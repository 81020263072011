import React from "react";
import  useContainerSize  from "../../hooks/useContainerSize";

const BaseContainer = ({ children }) => {
  const baseContainerRef = useContainerSize("base-container");
  return (
    <div
      ref={baseContainerRef}
      className="border-2 mx-auto p-2 rounded-2xl w-full min-h-dvh max-w-[1400px]"
      style={{borderColor: "#f35644"}}
    >
      {children}
    </div>
  );
};

export default BaseContainer;
