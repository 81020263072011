import { BiBookBookmark, BiBriefcase, BiCodeBlock, BiLogoReact, BiCodeCurly, BiCurrentLocation, BiSolidSchool, BiLogoAdobe, BiExpandHorizontal, BiPen } from "react-icons/bi"

export const questions = [
    {
        description: "¿Qué estudiaste?",
        icon: BiBookBookmark,
        color:"#eab308"
    },
    {
        description: "¿Cuál es tu experiencia laboral?",
        icon: BiBriefcase,
        color:"#eab308"
    },
    {
        description: "¿Qué tecnologías conoces?",
        icon: BiCodeBlock,
        color:"#06b6d4"
    },
    {
        description: "¿Cuál es tu experiencia con React?",
        icon: BiLogoReact,
        color:"#06b6d4"
    },
    {
        description: "¿Trabajas con React Native?",
        icon: BiLogoReact,
        color:"#06b6d4"
    },
    // {
    //     description: "¿Qué es lo que más te gusta de programar?",
    //     icon: BiCodeCurly,
    //     color:"#06b6d4"
    // },
    // {
    //     description: "¿De dónde eres?",
    //     icon: BiCurrentLocation,
    //     color:"#eab308"
    // },
    {
        description: "¿Dónde estudiaste?",
        icon: BiSolidSchool,
        color:"#eab308"
    },
    {
        description: "¿Sabes manejar la suite de Adobe?",
        icon: BiLogoAdobe,
        color:"#f43f5e"
    },
    {
        description: "¿Cómo desarrollas las páginas web?",
        icon: BiExpandHorizontal,
        color:"#06b6d4"
    },
    {
        description: "¿Qué tipo de diseño haces?",
        icon: BiPen,
        color:"#f43f5e"
    }

]

//Get a quantity of random set of questions
export const getQuestions = (quantity) => {
    let questionsCopy = [...questions]
    let randomQuestions = []
    for (let i = 0; i < quantity; i++) {
        let randomIndex = Math.floor(Math.random() * questionsCopy.length)
        randomQuestions.push(questionsCopy[randomIndex])
        questionsCopy.splice(randomIndex, 1)
    }
    return randomQuestions
}