import React, { useState, useEffect } from "react";
import ReactGridLayout from "react-grid-layout";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { useSize } from "../../contexts/ContainerSizeContext";
import useContainerSize from "../../hooks/useContainerSize";
import BlockContainer from "../BlockContainer";
import AskMe from "../AskMe";
import ImageComparator from "react-simple-image-comparator";

//Assets Import
import memoji from "../../assets/img/mateoMemoji.webp";
import spaceTripVideo from "../../assets/video/SpaceTripVideo.mp4";
import LogoSVG from "../shapes/MateoLogo";
import Duck from "../shapes/Duck";
import cotton from "../../assets/img/cotton.webp";
import cottonInc from "../../assets/img/cottonInc.webp";
import moonride from "../../assets/img/moonride.webp";
import penguin from "../../assets/img/penguin.webp";
import ingmultimedia from "../../assets/img/ingmultimedia.webp";
import WaveLine from "../shapes/WaveLine";
import TypingEffect from "../TypingEffect";
import Star from "../shapes/Star";
import { useTailwindBreakpoints } from "../../hooks/useTailwindBreakpoints";

function GridPage() {
  const memojiContainerRef = useContainerSize("memoji-container");
  const askMeContainerRef = useContainerSize("ask-me-container");
  const { size: baseContainerSize } = useSize("base-container");
  const { size: memojiContainerSize } = useSize("memoji-container");

  const { isMobile } = useTailwindBreakpoints();

  const layout = [
    { i: "title", x: 3, y: 0, w: 7, h: 1 },
    { i: "b", x: 0, y: 1, w: 3, h: 3 },
    { i: "spaceTripVideo", x: 3, y: 2, w: 2, h: 2 },
    { i: "duck", x: 3, y: 1, w: 2, h: 1 },
    { i: "memoji", x: 5, y: 1, w: 2, h: 3 },
    { i: "f", x: 7, y: 1, w: 2, h: 2 },
    { i: "g", x: 7, y: 3, w: 2, h: 1 },
    { i: "comparator", x: 9, y: 1, w: 3, h: 3 },
    { i: "separator", x: 0, y: 4, w: 4, h: 1 },
    { i: "separator2", x: 8, y: 4, w: 4, h: 1 },
    { i: "logo", x: 0, y: 0, w: 2, h: 1 },
    { i: "askme", x: 4, y: 4, w: 4, h: 1 },
  ];

  const mobileLayout = [
    { i: "title", x: 3, y: 0, w: 3, h: 1 },
    { i: "b", x: 0, y: 0, w: 0, h: 0 },
    { i: "spaceTripVideo", x: 0, y: 2, w: 2, h: 2 },
    { i: "duck", x: 0, y: 1, w: 2, h: 1 },
    { i: "memoji", x: 2, y: 1, w: 2, h: 3 },
    { i: "f", x: 4, y: 1, w: 2, h: 2 },
    { i: "g", x: 4, y: 3, w: 2, h: 1 },
    { i: "comparator", x: 0, y: 0, w: 0, h: 0 },
    { i: "separator", x: 0, y: 0, w: 0, h: 0 },
    { i: "separator2", x: 0, y: 0, w: 0, h: 0 },
    { i: "logo", x: 0, y: 0, w: 2, h: 1 },
    { i: "askme", x: 1, y: 4, w: 6, h: 1 },
  ];

  return (
    <ResponsiveGridLayout
      className="layout"
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 6 }}
      layouts={{
        lg: layout,
        md: layout,
        sm: layout,
        xs: mobileLayout,
        xxs: mobileLayout,
      }}
      autoSize
      margin={[10, 10]}
      isDraggable={false}
      isResizable={false}
      compactType={null}
      rowHeight={(baseContainerSize.width - 60) / (isMobile ? 6 : 12)} // Usamos el estado del ancho aquí
      width={baseContainerSize.width} // Usamos el estado del ancho aquí
    >
      <BlockContainer key="title" className="text-5xl">
        <h1 style={{ color: "#522ab1" }}>
          {/* Transforma tus ideas en experiencias deslumbrantes */}
        </h1>
      </BlockContainer>
      <BlockContainer key="b" className="">
        <img
          src={moonride}
          alt="Matte Painting de un motociclista en la luna"
          className="h-full w-full aspect-square object-cover shadow-xl rounded-2xl animate-float2"
        />
      </BlockContainer>
      <BlockContainer key="spaceTripVideo" className="">
        <video
          className="rounded-2xl animate-float1 shadow-xl"
          autoPlay
          loop
          muted
          playsInline
          alt="Video de un matte painting animado de un viaje espacial"
        >
          <source src={spaceTripVideo} type="video/mp4" />
          Tu navegador no soporta la reproducción de video.
        </video>
      </BlockContainer>
      <BlockContainer key="duck" className="">
        <div className="h-full w-full justify-center flex animate-float2">
          <Duck width="85%" />
        </div>
      </BlockContainer>
      <BlockContainer
        key="memoji"
        className="rounded-2xl z-10"
        style={{ background: "#f35644" }}
      >
        <div ref={memojiContainerRef} className="relative w-full h-full">
          <img
            src={memoji}
            alt="Memoji de Mateo Ávila"
            className="absolute z-10"
            style={{
              bottom: isMobile ? -20 : -55,
              width: memojiContainerSize.width + (isMobile ? 75 : 150),
              left: `calc(50% - ${
                (memojiContainerSize.width + (isMobile ? 75 : 150)) / 2
              }px)`,
            }}
          />
          <div className="relative h-full w-full overflow-hidden rounded-2xl">
            <Star className="absolute -bottom-10 animate-spin" width="200%" />
          </div>
        </div>
      </BlockContainer>
      <BlockContainer key="f" className="">
        <img
          src={penguin}
          alt="Matte painting de un pingüino y un helado"
          className="h-full w-full aspect-square object-cover rounded-2xl shadow-xl animate-float1"
        />
      </BlockContainer>
      <BlockContainer key="g" className="">
        <img
          src={ingmultimedia}
          alt="Ilustración de un banner de ingeniería multimedia"
          className="h-full w-full aspect-square object-cover rounded-2xl shadow-xl animate-float2"
        />
      </BlockContainer>
      <BlockContainer key="comparator" className="">
        <div className="h-full w-full animate-float1">
          <ImageComparator
            firstImg={cottonInc}
            secondImg={cotton}
            styles={{ container: { width: "100%", margin: 0 } }}
          />
        </div>
      </BlockContainer>
      <BlockContainer key="separator" className="rounded-2xl">
        <div className="w-full h-full hidden md:flex items-center relative">
          <WaveLine className="absolute left-[-18px]" />
        </div>
      </BlockContainer>
      <BlockContainer
        key="separator2"
        className="rounded-2xl flex items-center relative"
      >
        <div className="w-full h-full hidden md:flex items-center relative">
          <WaveLine className="absolute right-[-18px]" />
        </div>
      </BlockContainer>
      <BlockContainer key="logo" className="">
        <LogoSVG width="100%" />
      </BlockContainer>
      <BlockContainer key="askme" className="z-20">
        <div
          ref={askMeContainerRef}
          className="w-full h-full font-gabarito flex items-center"
        >
          <AskMe />
        </div>
      </BlockContainer>
    </ResponsiveGridLayout>
  );
}

export default GridPage;
