import { ContainerSizeProvider } from "./contexts/ContainerSizeContext";
import Home from "./pages/Home";
import "./App.css";
import BaseContainer from "./components/BaseContainer";

function App() {
  return (
    <ContainerSizeProvider>
      <BaseContainer>
        <Home />
      </BaseContainer>
    </ContainerSizeProvider>
  );
}

export default App;
