import React from "react";

function ScribIALogo({ className }: { className?: string }) {
  const logoColor = "#ce3348";
  return (
    <svg
      id="TextLogo"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      version="1.1"
      viewBox="0 0 400 120"
    >
      <path
        fill={logoColor}
        strokeWidth="0"
        d="M67.4,73.45c-1.46-2.76-3.36-5.15-5.71-7.17-2.36-2.02-4.91-3.79-7.68-5.32-2.76-1.53-5.41-2.89-7.95-4.09-2.31-1.19-4.52-2.41-6.61-3.64-2.09-1.23-3.79-2.67-5.09-4.31-1.31-1.64-1.96-3.66-1.96-6.05,0-2.61.73-4.8,2.18-6.55,1.46-1.75,3.72-2.63,6.78-2.63,3.28,0,6.34,1.14,9.18,3.41,2.84,2.28,5.04,6.59,6.61,12.94h9.3v-1.68c0-2.46-.06-5.19-.17-8.18-.11-2.98-.38-5.88-.79-8.68s-1.13-5.05-2.18-6.77l-9.52,2.91c-1.87-1.05-4.27-1.79-7.22-2.24s-5.51-.67-7.68-.67c-4.7,0-8.94.86-12.71,2.57-3.77,1.72-6.77,4.17-9.01,7.34s-3.36,7.04-3.36,11.59c0,6.27,1.9,11.48,5.71,15.63,3.81,4.14,9,7.82,15.57,11.03,2.53,1.19,5.05,2.52,7.56,3.97,2.5,1.46,4.57,3.08,6.21,4.87,1.64,1.8,2.47,3.92,2.47,6.39,0,1.19-.3,2.46-.9,3.81-.6,1.34-1.55,2.48-2.86,3.41-1.3.94-3.11,1.4-5.43,1.4-4.7,0-8.83-1.79-12.37-5.37-3.55-3.59-5.92-8.55-7.12-14.9h-11.2c0,2.99.25,6.39.73,10.19.49,3.81,1.12,7.45,1.91,10.92.78,3.48,1.66,6.18,2.63,8.12l8.62-3.8c3.44,1.41,6.67,2.4,9.69,2.96,3.02.56,5.81.84,8.34.84,5.53,0,10.23-1.02,14.12-3.08,3.88-2.05,6.87-4.77,8.96-8.17,2.09-3.4,3.13-7.15,3.13-11.26,0-3.73-.73-6.98-2.18-9.74Z"
      />
      <path
        fill={logoColor}
        strokeWidth="0"
        d="M119.87,89.79c-1.42,1.12-3.16,1.98-5.21,2.57-2.05.6-4.09.9-6.1.9-4.33,0-7.69-1.66-10.08-4.98-2.39-3.32-3.59-9.09-3.59-17.31,0-5.07.51-9.03,1.52-11.87,1-2.84,2.25-4.81,3.75-5.94,1.49-1.11,2.95-1.67,4.37-1.67.44,0,.91.05,1.4.16.48.11,1.1.4,1.84.84-.97.75-1.77,1.68-2.4,2.8-.64,1.12-.96,2.58-.96,4.37,0,2.46.79,4.56,2.36,6.27,1.56,1.72,3.5,2.58,5.82,2.58,2.84,0,5.15-.97,6.94-2.91,1.8-1.94,2.69-4.71,2.69-8.29,0-3.96-1.49-7.3-4.48-10.02-2.99-2.73-7.35-4.09-13.1-4.09s-10.49,1.25-14.9,3.75c-4.4,2.5-7.88,6.12-10.41,10.86-2.54,4.75-3.81,10.44-3.81,17.08s1.17,11.93,3.53,16.52c2.35,4.6,5.6,8.12,9.74,10.59,4.14,2.46,8.94,3.69,14.39,3.69,3.51,0,6.68-.52,9.52-1.57,2.84-1.04,5.1-2.36,6.78-3.97,1.68-1.61,2.52-3.19,2.52-4.76,0-.82-.21-1.77-.62-2.86-.41-1.08-.91-1.99-1.51-2.74Z"
      />
      <path
        fill={logoColor}
        strokeWidth="0"
        d="M173.07,45.9c-1.49-1.8-4-2.69-7.5-2.69-2.17,0-4.26.48-6.28,1.45-2.01.97-3.82,2.23-5.43,3.76-1.61,1.53-2.82,3.08-3.64,4.64,0-3.95-.39-6.53-1.17-7.72-.79-1.2-2.04-1.8-3.76-1.8-1.34,0-3.21.25-5.6.73-2.39.49-4.81,1.12-7.28,1.9-2.46.79-4.59,1.7-6.38,2.75v5.04c2.84,0,4.78.43,5.82,1.29,1.05.86,1.57,2.26,1.57,4.2v32.37c0,2.24-.47,3.82-1.4,4.75-.93.94-2.48,1.41-4.65,1.41,0,1.27.23,2.38.68,3.36.44.97,1.08,1.86,1.9,2.68h29.9c.82-.82,1.38-1.71,1.68-2.68.3-.98.45-2.09.45-3.36-3.06,0-5.36-.25-6.89-.73-1.53-.49-2.56-1.25-3.08-2.3-.52-1.04-.78-2.42-.78-4.14v-33.6c1.12-1.05,2.24-1.81,3.36-2.3,1.12-.48,2.28-.88,3.47-1.18-.22.68-.33,1.61-.33,2.81,0,1.86.74,3.52,2.24,4.98,1.49,1.46,3.5,2.18,6.04,2.18,2.92,0,5.19-1.01,6.84-3.02,1.64-2.02,2.46-4.63,2.46-7.84,0-2.84-.75-5.15-2.24-6.94Z"
      />
      <path
        fill={logoColor}
        strokeWidth="0"
        d="M202.13,17.22c-1.9-1.94-4.5-2.91-7.78-2.91s-5.81.97-7.79,2.91c-1.97,1.95-2.96,4.48-2.96,7.62s.95,5.77,2.85,7.67,4.46,2.86,7.67,2.86,5.92-.95,7.9-2.86c1.98-1.9,2.97-4.46,2.97-7.67s-.95-5.67-2.86-7.62ZM204.54,96.62c-1.12-.9-1.68-2.46-1.68-4.7v-43.57c0-1.87-.37-3.19-1.12-3.98-.75-.78-1.98-1.17-3.7-1.17-2.16,0-4.61.3-7.33.89-2.73.6-5.25,1.33-7.56,2.19-2.32.86-4.03,1.62-5.15,2.29v4.93c3.06,0,5.05.49,5.99,1.46.93.97,1.4,2.42,1.4,4.37v32.48c0,2.24-.52,3.82-1.57,4.75-1.05.94-2.65,1.41-4.82,1.41,0,1.27.23,2.38.68,3.36.44.97,1.08,1.86,1.9,2.68h25.31c.82-.82,1.46-1.71,1.91-2.68.44-.98.67-2.09.67-3.36-2.17,0-3.81-.45-4.93-1.35Z"
      />
      <path
        fill={logoColor}
        strokeWidth="0"
        d="M267.15,56.14c-2.24-4.21-5.15-7.37-8.74-9.46-3.58-2.09-7.43-3.14-11.53-3.14-2.54,0-4.86.34-6.95,1.01-2.09.67-3.88,1.79-5.37,3.36l.33-10.64v-15.9c0-1.87-.37-3.2-1.12-3.98-.75-.78-2.01-1.18-3.81-1.18-1.56,0-3.56.3-5.99.9-2.42.6-4.83,1.36-7.22,2.3-2.39.93-4.44,2-6.16,3.19v3.58c2.46,0,4.22.49,5.26,1.46,1.05.97,1.57,2.31,1.57,4.03v69.67c1.79.89,4.03,1.62,6.72,2.18s5.45.99,8.29,1.29c2.83.3,5.34.45,7.5.45,5.6,0,10.72-1.25,15.35-3.76,4.62-2.5,8.32-6.19,11.08-11.08,2.77-4.89,4.15-10.92,4.15-18.09,0-6.57-1.12-11.97-3.36-16.19ZM249.79,88.57c-.97,3.06-2.3,5.24-3.98,6.55s-3.53,1.96-5.54,1.96c-2.17,0-3.96-.71-5.38-2.13v-40.65c1.2-.83,2.62-1.24,4.26-1.24,3.43,0,6.31,1.8,8.62,5.38,2.32,3.58,3.47,9.48,3.47,17.69,0,5.23-.48,9.38-1.45,12.44Z"
      />
      <path
        fill={logoColor}
        strokeWidth="0"
        d="M300.3,91.26v-52.65c0-2.08.5-3.56,1.51-4.42s2.75-1.29,5.21-1.29c0-1.27-.22-2.53-.67-3.81-.45-1.26-1.05-2.35-1.79-3.24h-27.22c-1.64,1.86-2.46,4.22-2.46,7.05,2.46,0,4.2.43,5.2,1.29,1.01.86,1.52,2.34,1.52,4.42v52.65c0,2.09-.51,3.54-1.52,4.36-1,.82-2.74,1.24-5.2,1.24,0,2.91.82,5.3,2.46,7.16h27.22c.74-.89,1.34-1.97,1.79-3.25.45-1.26.67-2.57.67-3.91-2.46,0-4.2-.42-5.21-1.24s-1.51-2.27-1.51-4.36Z"
      />
      <path
        fill={logoColor}
        strokeWidth="0"
        d="M382.95,94.9c-1.79-1.31-3.09-3.2-3.92-5.66l-20.83-63.39h-30.69c-1.79,2.16-2.68,4.85-2.68,8.06,4.55,0,8.32.49,11.31,1.46l-18.37,54.2c-.82,2.47-2.11,4.3-3.86,5.49-1.76,1.2-3.64,1.8-5.66,1.8,0,2.91.86,5.3,2.58,7.16h24.97c1.72-1.86,2.58-4.25,2.58-7.16-2.24,0-4-.45-5.27-1.35s-1.9-2.35-1.9-4.37c0-.67.09-1.41.28-2.24.19-.82.43-1.71.73-2.69l.67-2.35h25.09l.67,2.35c.3,1.05.5,2.02.62,2.92.11.89.16,1.68.16,2.35,0,1.87-.57,3.23-1.73,4.09-1.16.86-2.71,1.29-4.65,1.29,0,2.91.86,5.3,2.58,7.16h30.35c1.72-1.86,2.57-4.25,2.57-7.16-1.94,0-3.8-.66-5.6-1.96ZM359.46,59.65l-1.52.38c-4,1.01-7.12,4.13-8.13,8.13l-.38,1.52c-.26,1.04-1.74,1.04-2,0l-.38-1.52c-1.01-4-4.13-7.12-8.13-8.13l-1.52-.38c-1.04-.26-1.04-1.74,0-2l1.52-.38c4-1.01,7.12-4.13,8.13-8.13l.38-1.52c.26-1.04,1.74-1.04,2,0l.38,1.52c1.01,4,4.13,7.12,8.13,8.13l1.52.38c1.04.26,1.04,1.74,0,2Z"
      />
    </svg>
  );
}

export default ScribIALogo;
