import React from "react";

const Duck = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        width: props.width || "100%",
        opacity: props.opacity,
        transition: "all 1.2s ease",
      }}
    >
      <svg
        viewBox="0 0 1080 675"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="duck">
          <path
            id="lake"
            d="M1038.02 565.5C1038.02 596.76 1012.67 622.12 981.4 622.12H98.11C66.85 622.12 41.49 596.77 41.49 565.5C41.49 534.22 66.84 508.87 98.11 508.87H256.37C287.63 508.87 312.99 483.52 312.99 452.24C312.99 420.98 287.64 395.62 256.37 395.62H186.04C154.76 395.62 129.41 370.27 129.41 338.99C129.41 307.71 154.76 282.36 186.04 282.36H893.48C924.76 282.36 950.11 307.71 950.11 338.99C950.11 370.27 924.76 395.62 893.48 395.62H823.15C791.89 395.62 766.53 420.97 766.53 452.24C766.53 483.52 791.88 508.87 823.15 508.87H981.41C1012.67 508.87 1038.02 534.22 1038.02 565.5Z"
            fill="#049DD9"
          />
          <g id="duck_2" className="animate-moveUpAndDown">
            <path
              id="Vector"
              d="M394.13 83.94L388.64 76.43C388.69 76.39 388.81 76.29 389 76.14C389.59 75.55 392.91 71.61 390.21 60.29C389.61 57.78 391.16 55.27 393.67 54.67C396.19 54.08 398.69 55.62 399.29 58.13C400.37 62.68 400.7 66.5 400.55 69.68C406.34 67.48 413.65 66.18 421.11 68.7C423.55 69.53 424.86 72.17 424.03 74.61C423.2 77.05 420.56 78.36 418.12 77.53C407.85 74.05 396.89 81.85 394.91 83.36C394.54 83.66 394.26 83.85 394.13 83.94Z"
              fill="#D79104"
            />
            <path
              id="Vector_2"
              d="M783.64 282.36C783.64 415.88 675.4 524.13 541.88 524.13C408.36 524.13 300.13 415.88 300.13 282.36V139.09C300.13 102.9 329.46 73.57 365.65 73.57C383.74 73.57 400.13 80.9 411.98 92.76C423.83 104.61 431.17 120.99 431.17 139.09V282.36H783.64Z"
              fill="#F2CB05"
            />
            <path
              id="Vector_3"
              d="M335.56 147.61C335.56 155.08 329.51 161.13 322.04 161.13C314.57 161.13 308.52 155.08 308.52 147.61C308.52 140.14 314.57 134.09 322.04 134.09C329.51 134.09 335.56 140.14 335.56 147.61Z"
              fill="#D79104"
            />
            <path
              id="Vector_4"
              d="M367.79 147.61C367.79 155.08 361.74 161.13 354.27 161.13C346.8 161.13 340.75 155.08 340.75 147.61C340.75 140.14 346.8 134.09 354.27 134.09C361.74 134.09 367.79 140.14 367.79 147.61Z"
              fill="#D79104"
            />
            <path
              id="Vector_5"
              d="M358.56 194.37C358.56 204.49 350.36 212.69 340.24 212.69H266.22C256.1 212.69 247.9 204.49 247.9 194.37C247.9 184.25 256.1 176.05 266.22 176.05H340.24C350.36 176.05 358.56 184.25 358.56 194.37Z"
              fill="#f23006"
            />
            <path
              id="Vector_6"
              d="M562.46 450.01C509.66 450.01 465.88 407.93 464.17 354.78C463.92 346.83 464.62 338.88 466.25 331.17C467.32 326.13 472.27 322.92 477.31 323.98C482.35 325.05 485.57 330 484.5 335.04C483.17 341.29 482.61 347.73 482.82 354.19C484.2 397.26 519.68 431.36 562.48 431.37C563.34 431.37 564.2 431.36 565.07 431.33C597.17 430.3 625.38 410.27 636.94 380.3C638.79 375.49 644.19 373.1 649 374.96C653.81 376.81 656.2 382.21 654.35 387.02C640.09 424 605.28 448.71 565.68 449.98C564.58 449.99 563.52 450.01 562.46 450.01Z"
              fill="#D79104"
            />
          </g>
        </g>
      </svg>
    </div>
  );
});

export default Duck;
