import React from "react";
import { colorLight, colorDark } from "../../helpers/ColorManipulation";
function SuggestionCard({ Icon, description, onClick, color }) {
  return (
    <div className="w-full p-2" onClick={onClick}>
      <div className="flex flex-row p-3 rounded-xl shadow-sm items-center cursor-pointer hover:shadow-xl transition duration-300 ease-in-out bg-white">
        <div
          className="rounded-full flex items-center justify-center w-8 h-8 mr-2"
          style={{ background: colorLight(color) }}
        >
          <Icon
            className="text-lg md:text-xl"
            style={{ color: colorDark(color) }}
          />
        </div>
        <p
          className="text-sm md:text-lg text"
          style={{ color: colorDark(color) }}
        >
          {description}
        </p>
      </div>
    </div>
  );
}

export default SuggestionCard;
