import React, { useState, useRef, useEffect } from "react";
import Card from "../../../components/Card";
import CardTitle from "../../../components/Card/CardTitle";
import { BiPen, BiCodeAlt, BiMobileAlt } from "react-icons/bi";
import { FaReact, FaMicrosoft, FaNpm } from "react-icons/fa";
import {
  SiAdobeillustrator,
  SiAdobelightroomclassic,
  SiAdobephotoshop,
  SiGooglecloud,
  SiMaterialdesign,
  SiNextdotjs,
  SiNodedotjs,
  SiNpm,
} from "react-icons/si";
import CardBody from "../../../components/Card/CardBody";
import { motion, AnimatePresence } from "framer-motion";
import Carrousel from "../../../components/Carrousel";
import GeneralModal from "../../../components/GeneralModal";

import landscapeEdited from "../../../assets/img/trickOrTreat.webp";
import landscape from "../../../assets/img/Landscape.webp";
import cottonInc from "../../../assets/img/cottonInc.webp";
import mosaic from "../../../assets/img/mosaic.webp";
import nequi from "../../../assets/img/nequi.webp";
import qrFastLogo from "../../../assets/img/qrFastLogo.webp";
import moveFest from "../../../assets/img/movefest.webp";
import Tag from "../../../components/Tag";
import Button from "../../../components/Button";
import ScribIALogo from "../../../assets/components/ScribIALogo";
import ImageComparator from "react-simple-image-comparator";
import Tabs from "../../../components/Tabs";

const PortfolioCard = React.memo(
  ({
    imgSrc,
    altText,
    bodyText,
    tags,
    buttonText,
    buttonUrl,
    component,
    styles,
  }) => {
    const handleButtonClick = () => {
      if (buttonUrl) window.open(buttonUrl, "_blank");
    };
    return (
      <motion.div
        className="break-inside"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        <Card className="w-full mt-4" style={styles}>
          {imgSrc && (
            <img
              src={imgSrc}
              alt={altText}
              className="w-full rounded-lg m-auto"
              loading="lazy"
              style={{ width: "100%", height: "auto" }}
            />
          )}
          {component && component}
          <CardBody color={tags[0].color}>{bodyText}</CardBody>
          <div className="flex items-center">
            <div className="flex flex-wrap mt-4">
              {tags.map((tag) => (
                <Tag
                  key={tag.text}
                  color={tag.color}
                  icon={tag.icon}
                  size="small"
                >
                  {tag.text}
                </Tag>
              ))}
            </div>
            {buttonText && (
              <Button
                className="m-auto"
                color={styles.borderColor}
                onClick={handleButtonClick}
              >
                {buttonText}
              </Button>
            )}
          </div>
        </Card>
      </motion.div>
    );
  }
);

function Portfolio() {
  const cardsData = [
    {
      component: (
        <img src={qrFastLogo} alt="Logo QRFast" className="w-2/3 m-auto" />
      ),
      bodyText:
        "QRFast es una aplicación web para generar códigos QR de manera fácil y rápida. Esta aplicación está construida con React, TypeScript y Vite, y utiliza varias bibliotecas para mejorar la experiencia del usuario.",
      tags: [
        { color: "#542ea6", icon: FaReact, text: "React" },
        { color: "#542ea6", icon: SiMaterialdesign, text: "Material" },
      ],
      type: "proyectos",
      buttonText: "Visitar",
      buttonUrl: "https://qrfast.mateoavila.com",
      styles: {
        background: "#e0d7f4",
        borderWidth: "2px",
        borderColor: "#542ea6",
        boxShadow: "0px 5px 0px 0px #542ea6",
      },
    },
    {
      imgSrc: moveFest,
      altText: "moveFest",
      bodyText:
        "Sitio web en desarrollo para Xall Move Fest. Está siendo construido con NextJS, Tailwind, y Google Cloud para obtener información sobre los eventos, las reservas y generacion de QR's únicos para las entradas.",
      tags: [
        { color: "#d91470", icon: SiNextdotjs, text: "NextJS" },
        { color: "#d91470", icon: SiGooglecloud, text: "GCloud" },
      ],
      type: "proyectos",
      styles: {
        background: "#fbd0e4",
        borderWidth: "2px",
        borderColor: "#d91470",
        boxShadow: "0px 5px 0px 0px #d91470",
      },
    },
    {
      imgSrc: nequi,
      altText: "Matte painting para un concurso de Nequi",
      bodyText: "",
      tags: [{ color: "#ce3348", icon: SiAdobephotoshop, text: "Photoshop" }],
      type: "diseños",
      buttonText: "",
      styles: {
        background: "#f5d6da",
        borderWidth: "2px",
        borderColor: "#ce3348",
        boxShadow: "0px 5px 0px 0px #ce3348",
      },
    },
    {
      imgSrc: cottonInc,
      altText: "Matte painting de una oveja de algodón de azúcar",
      bodyText: "",
      tags: [
        { color: "#542ea6", icon: SiAdobephotoshop, text: "Photoshop" },
        { color: "#542ea6", icon: SiAdobelightroomclassic, text: "LightRoom" },
      ],
      type: "diseños",
      buttonText: "",
      styles: {
        background: "#e0d7f4",
        borderWidth: "2px",
        borderColor: "#542ea6",
        boxShadow: "0px 5px 0px 0px #542ea6",
      },
    },
    {
      imgSrc: mosaic,
      altText: "Ilustración de un mosaico con varias ilustraciones",
      bodyText: "",
      tags: [
        { color: "#ce3348", icon: SiAdobeillustrator, text: "Illustrator" },
      ],
      type: "diseños",
      buttonText: "",
      styles: {
        background: "#f5d6da",
        borderWidth: "2px",
        borderColor: "#ce3348",
        boxShadow: "0px 5px 0px 0px #ce3348",
      },
    },
    {
      component: (
        <ImageComparator
          firstImg={landscape}
          secondImg={landscapeEdited}
          ratio="16/9"
          styles={{ container: { width: "100%", margin: 0 } }}
        />
      ),
      bodyText: (
        <>
          <p className="font-bold">react-simple-image-comparator</p>
          Componente para React que permite comparar dos imágenes mediante un
          deslizador que revela más o menos de la segunda imagen. El componente
          utiliza styled-components para aplicar estilos CSS a los diferentes
          elementos.
        </>
      ),
      tags: [
        { color: "#0597F2", icon: FaReact, text: "React" },
        { color: "#0597F2", icon: SiNpm, text: "NPM" },
      ],
      type: "proyectos",
      buttonText: "Docs",
      buttonUrl: "https://github.com/Mateo172001/react-simple-image-comparator",
      styles: {
        background: "#cdebfe",
        borderWidth: "2px",
        borderColor: "#0597F2",
        boxShadow: "0px 5px 0px 0px #0597F2",
      },
    },
    {
      component: <ScribIALogo className="w-2/3 m-auto" />,
      bodyText:
        "ScribIA es una aplicación web que genera resúmenes de texto y documentos utilizando la API de Gemini. La aplicación permite a los usuarios ingresar texto o subir documentos, seleccionar la longitud, el tono y el idioma del resumen, y obtener un resumen generado por IA.",
      tags: [
        { color: "#ce3348", icon: FaReact, text: "React" },
        { color: "#ce3348", icon: SiNodedotjs, text: "Node" },
        { color: "#ce3348", icon: SiGooglecloud, text: "G Cloud" },
        { color: "#ce3348", icon: FaMicrosoft, text: "FluentUI" },
      ],
      type: "proyectos",
      buttonText: "Visitar",
      buttonUrl: "https://scribia.mateoavila.com",
      styles: {
        background: "#f5d6da",
        borderWidth: "2px",
        borderColor: "#ce3348",
        boxShadow: "0px 5px 0px 0px #ce3348",
      },
    },
  ];
  const [filteredCards, setFilteredCards] = useState(cardsData);
  const [isTabSelected, setIsTabSelected] = useState(false);
  const portfolioRef = useRef(null);

  const tabs = [
    { name: "Todos", icon: BiMobileAlt },
    { name: "Proyectos", icon: BiCodeAlt },
    { name: "Diseños", icon: BiPen },
  ];

  const handleTabChange = (index) => {
    if (!isTabSelected) {
      setIsTabSelected(true);
      const filtered = cardsData.filter(
        (card) => card.type === tabs[index].name.toLowerCase()
      );
      setFilteredCards(filtered);
      return;
    }
    if (index === 0) {
      setFilteredCards(cardsData);
    } else {
      const filtered = cardsData.filter(
        (card) => card.type === tabs[index].name.toLowerCase()
      );
      setFilteredCards(filtered);
    }
  };

  const handleTabClick = () => {
    if (portfolioRef.current) {
      portfolioRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  // useEffect(() => {
  //   if (portfolioRef.current) {
  //     portfolioRef.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //     });
  //   }
  // }, [filteredCards]);

  return (
    <div ref={portfolioRef} className="mt-16 mx-auto w-full max-w-screen-lg">
      <Tabs
        tabs={tabs}
        onChange={handleTabChange}
        onTabClick={handleTabClick}
      />

      <motion.div
        layout
        transition={{ duration: 0.4 }}
        className="font-gabarito w-full min-h-24 mx-auto p-8 masonry sm:masonry-sm md:masonry-md"
      >
        <AnimatePresence>
          {filteredCards.map((card, index) => (
            <PortfolioCard key={index} {...card} />
          ))}
        </AnimatePresence>
      </motion.div>
    </div>
  );
}

export default Portfolio;
