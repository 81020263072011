import React from "react";
import Tag from "../../../components/Tag";
import {
  BiLogoReact,
  BiLogoHtml5,
  BiLogoCss3,
  BiLogoTailwindCss,
  BiLogoJavascript,
  BiLogoTypescript,
  BiLogoNodejs,
  BiLogoGit,
  BiLogoGithub,
} from "react-icons/bi";

import { useTailwindBreakpoints } from "../../../hooks/useTailwindBreakpoints";

import {
  SiAdobephotoshop,
  SiAdobeillustrator,
  SiFigma,
  SiAdobelightroom,
  SiNextdotjs,
  SiExpress,
  SiMongodb,
  SiFirebase,
  SiKotlin,
} from "react-icons/si";

function SkillSet() {
  const { isMobile } = useTailwindBreakpoints();

  return (
    <div className={`flex ${isMobile ? "overflow-x-scroll py-4" : "flex-wrap"}`}>
      <Tag color="#f35644" icon={BiLogoReact}>
        React
      </Tag>
      <Tag color="#f35644" icon={BiLogoReact}>
        React Native
      </Tag>
      <Tag color="#f35644" icon={SiNextdotjs}>
        Next.js
      </Tag>
      <Tag color="#f35644" icon={BiLogoNodejs}>
        Node.js
      </Tag>
      <Tag color="#f35644" icon={SiExpress}>
        Express
      </Tag>
      <Tag color="#f35644" icon={BiLogoTypescript}>
        TypeScript
      </Tag>
      <Tag color="#f35644" icon={BiLogoHtml5}>
        HTML
      </Tag>
      <Tag color="#f35644" icon={BiLogoCss3}>
        CSS
      </Tag>
      <Tag color="#f35644" icon={BiLogoTailwindCss}>
        Tailwind CSS
      </Tag>
      <Tag color="#f35644" icon={SiKotlin}>
        Kotlin
      </Tag>
      <Tag color="#f35644" icon={BiLogoGit}>
        Git
      </Tag>
      <Tag color="#f35644" icon={BiLogoGithub}>
        GitHub
      </Tag>
      <Tag color="#f35644" icon={SiMongodb}>
        MongoDB
      </Tag>
      <Tag color="#f35644" icon={SiFirebase}>
        Firebase
      </Tag>
      <Tag color="#f35644" icon={SiAdobephotoshop}>
        Photoshop
      </Tag>
      <Tag color="#f35644" icon={SiAdobeillustrator}>
        Illustrator
      </Tag>
      <Tag color="#f35644" icon={SiAdobelightroom}>
        Lightroom
      </Tag>
      <Tag color="#f35644" icon={SiFigma}>
        Figma
      </Tag>
    </div>
  );
}

export default SkillSet;
