import React from "react";

function CardTitle(props) {
  return (
    <div
      {...props}
      className={`${props.className} rounded-full py-2 px-4 w-fit`}
    >
      <div className="flex items-center">
        <div
          className={`rounded-full flex items-center ${
            props.children && "mr-2"
          } p-2 -ml-2`}
          style={{ background: "#fed6cd" }}
        >
          {props.icon && <props.icon className="text-xl" color="#f35644" />}
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default CardTitle;
