import React from 'react';
import { useSpring, animated } from 'react-spring';

const WaveLine = ({className}) => {

  return (
    <div className={className} style={{ overflow: 'hidden', width: 'calc(100% + 5rem + 20px)', height: 'fit-content' }}>
      <svg
        style={{  width: '200%' }}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 764 38"
        className='animate-wave'
      >
        <path
          fill="none"
          stroke="#f35644"
          strokeMiterlimit={10}
          strokeWidth={13}
          d="M-1,14c10.52,0,10.52,10,21.05,10s10.52-10,21.05-10,10.53,10,21.05,10,10.53-10,21.05-10,10.53,10,21.05,10,10.53-10,21.05-10,10.53,10,21.05,10,10.53-10,21.05-10,10.52,10,21.05,10,10.53-10,21.05-10,10.53,10,21.05,10,10.53-10,21.05-10,10.52,10,21.05,10,10.53-10,21.05-10,10.53,10,21.05,10,10.53-10,21.05-10,10.53,10,21.05,10,10.53-10,21.05-10,10.53,10,21.05,10,10.53-10,21.05-10,10.53,10,21.05,10,10.53-10,21.05-10"
        />
        
      </svg>
    </div>
  );
};

export default WaveLine;
