import { useEffect, useRef } from 'react';
import { useSize } from '../contexts/ContainerSizeContext';
import { throttle } from 'lodash';

const useContainerSize = (id) => {
    const ref = useRef();
    const { setSize } = useSize(id); // Proporcionar un valor predeterminado vacío para size

    useEffect(() => {
        const observeElement = ref.current;

        // Aplicar throttle a la función que actualiza el tamaño
        const throttledSetSize = throttle((entries) => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                setSize({ width, height });
            }
        }, 200);  // Ajusta el tiempo de espera según sea necesario

        const resizeObserver = new ResizeObserver(throttledSetSize);

        if (observeElement) {
            resizeObserver.observe(observeElement);
        }

        return () => {
            if (observeElement) {
                resizeObserver.unobserve(observeElement);
            }
        };
    }, [setSize]);

    return ref;
};

export default useContainerSize;
