import React from "react";
import TypingEffect from "../../../components/TypingEffect";
import useContainerSize from "../../../hooks/useContainerSize";
import { useSize } from "../../../contexts/ContainerSizeContext";
import Card from "../../../components/Card";
import CardTitle from "../../../components/Card/CardTitle";
import { BiPen, BiCodeAlt, BiMobileAlt } from "react-icons/bi";
import { color } from "framer-motion";
import CardBody from "../../../components/Card/CardBody";
import { FaAngleDoubleDown } from "react-icons/fa";

function WhatIDo() {
  const headerWhatIDoRef = useContainerSize("header-what-i-do");
  const { size: headerWhatIDoWidth } = useSize("header-what-i-do");
  return (
    <div className="font-gabarito w-full max-w-screen-xl mx-auto">
      <h2
        className="text-4xl md:text-7xl font-titan text-center my-10 md:mt-24 md:mb-18"
        style={{ color: "#522ab1" }}
      >
        Transforma tus ideas en experiencias deslumbrantes
        <br />
        <FaAngleDoubleDown
          className="text-center w-full mt-10 animate-bounce"
          style={{ color: "#522ab1" }}
        />
      </h2>
      <div className="grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-3 p-4">
        <Card
          className="md:-rotate-1"
          style={{
            background: "#fed6cd",
            borderWidth: "2px",
            borderColor: "#f35644",
            boxShadow: "0px 5px 0px 0px #f35644",
          }}
        >
          <CardTitle
            className="font-bold"
            style={{ background: "#f35644", color: "#fed6cd" }}
            icon={BiPen}
          >
            Diseño
          </CardTitle>
          <CardBody color="#f35644">
            Piezas gráficas impactantes y diseño de interfaces web intuitivas
            que fortalecen tu marca, mejoran la experiencia del usuario y
            generan resultados.
          </CardBody>
        </Card>
        <Card
          className="md:-mt-6"
          style={{
            background: "#fed6cd",
            borderWidth: "2px",
            borderColor: "#f35644",
            boxShadow: "0px 5px 0px 0px #f35644",
          }}
        >
          <CardTitle
            className="font-bold"
            style={{ background: "#f35644", color: "#fed6cd" }}
            icon={BiCodeAlt}
          >
            Desarrollo Web
          </CardTitle>

          <CardBody color="#f35644">
            Diseño y desarrollo de sitios y aplicaciones web visualmente
            atractivos, intuitivos y accesibles, con énfasis en la experiencia
            del usuario.
          </CardBody>
        </Card>
        <Card
          className="md:rotate-1"
          style={{
            background: "#fed6cd",
            borderWidth: "2px",
            borderColor: "#f35644",
            boxShadow: "0px 5px 0px 0px #f35644",
          }}
        >
          <CardTitle
            className="font-bold"
            style={{ background: "#f35644", color: "#fed6cd" }}
            icon={BiMobileAlt}
          >
            Desarrollo Móvil
          </CardTitle>

          <CardBody color="#f35644">
            Desarrollo de apps móviles nativas multiplataforma con React Native,
            destacando por su diseño, funcionalidad y rendimiento.
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default WhatIDo;
