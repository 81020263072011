import React from "react";
import { colorDark } from "../../helpers/ColorManipulation";

function CardBody(props) {
  return (
    <div
      {...props}
      className={`${props.className} my-2 ml-4`}
      style={{
        ...props.style,
        color: colorDark(props.color),
        lineHeight: "1.3",
      }}
    >
      {props.children}
    </div>
  );
}

export default CardBody;
