import React from "react";

function FooterWaves(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1400 100"
      className={props.className}
      style={props.style}
    >
      <path
        fill="#f35644"
        d="M1368.5,47c-8.6-5.2-17.5-10.5-35.1-10.5s-26.5,5.3-35.1,10.5c-8.1,4.9-15.8,9.5-31.5,9.5s-23.4-4.6-31.5-9.5c-8.6-5.2-17.5-10.5-35.1-10.5s-26.5,5.3-35.1,10.5c-8.1,4.9-15.8,9.5-31.5,9.5s-23.4-4.6-31.5-9.5c-8.6-5.2-17.5-10.5-35.1-10.5s-26.5,5.3-35.1,10.5c-8.1,4.9-15.8,9.5-31.5,9.5s-23.4-4.6-31.5-9.5c-8.6-5.2-17.5-10.5-35.1-10.5s-26.5,5.3-35.1,10.5c-8.1,4.9-15.8,9.5-31.5,9.5s-23.4-4.6-31.5-9.5c-8.6-5.2-17.5-10.5-35.1-10.5s-26.5,5.3-35.1,10.5c-8.1,4.9-15.8,9.5-31.5,9.5s-23.4-4.6-31.5-9.5c-8.6-5.2-17.5-10.5-35.1-10.5s-26.5,5.3-35.1,10.5c-8.1,4.9-15.8,9.5-31.5,9.5s-23.4-4.6-31.5-9.5c-8.6-5.2-17.5-10.5-35.1-10.5s-26.5,5.3-35.1,10.5c-8.1,4.9-15.8,9.5-31.5,9.5s-23.4-4.6-31.5-9.5c-8.6-5.2-17.5-10.5-35.1-10.5s-26.5,5.3-35.1,10.5c-8.1,4.9-15.8,9.5-31.5,9.5s-23.4-4.6-31.5-9.5c-8.6-5.2-17.5-10.5-35.1-10.5s-26.5,5.3-35.1,10.5c-8.1,4.9-15.8,9.5-31.5,9.5s-23.4-4.6-31.5-9.5c-8.6-5.2-17.5-10.5-35.1-10.5s-26.5,5.3-35.1,10.5c-8.1,4.9-15.8,9.5-31.5,9.5s-23.4-4.6-31.5-9.5c-8.6-5.2-17.5-10.5-35.1-10.5v63.5h1400v-43.5c-15.7,0-23.4-4.6-31.5-9.5Z"
      />
    </svg>
  );
}

export default FooterWaves;
