import React, { forwardRef } from "react";

const LogoSVG = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        width: props.width,
        maxWidth: props.maxWidth,
        alignSelf: props.alignSelf || "center",
        opacity: props.opacity,
        margin: "auto",
        transition: "all 1.2s ease",
      }}
    >
      <svg
        className={props.className}
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 600 264.96"
        enableBackground="new 0 0 600 264.96"
        fill={props.fill || "#f35644"}
      >
        <g>
          <path
            d="M600,228.04c0,12.71,0,24.69,0,36.92c-200.06,0-399.94,0-600,0c0-63.77,0-127.48,0-191.34
            c15.16,0,30.03,0,45.28,0c8.48,23.03,16.97,46.06,25.85,70.18c8.65-24.08,16.93-47.15,25.18-70.14c15.19,0,30.06,0,45.22,0
            c0,39.2,0,78.23,0,117.61c-12.03,0-24.15,0-36.66,0c0-18.38,0-36.77,0-55.15c-0.3-0.05-0.59-0.11-0.89-0.16
            c-5.74,18.46-11.48,36.92-17.25,55.47c-10.64,0-21.05,0-31.86,0c-5.62-18.08-11.24-36.19-16.86-54.29
            c-0.23,0.05-0.45,0.11-0.68,0.17c0,30.14,0,60.27,0,90.74C224.86,228.04,412.25,228.04,600,228.04z"
          />
          <path
            d="M311.41,37.04c-39.85,0-78.95,0-118.29,0c0-12.49,0-24.61,0-37.04c88.81,0,177.51,0,266.3,0
            c0,12.37,0,24.49,0,36.91c-36.96,0-73.69,0-110.85,0c0,51.5,0,102.7,0,154.3c-12.51,0-24.61,0-37.16,0
            C311.41,140.14,311.41,88.96,311.41,37.04z"
          />
          <path
            d="M591.28,132.51c0.98,32.8-26.15,60.42-60.96,60.17c-33.6-0.24-60.84-27.01-60.32-61.06
            c0.52-34.58,26.27-60.15,61.72-60.02C567.85,71.74,592.68,100.88,591.28,132.51z M554.16,132.94c0.13-13.28-10.38-24-23.49-23.97
            c-12.68,0.04-23.24,10.46-23.44,23.14c-0.21,13,10.22,23.77,23.09,23.86C543.55,156.06,554.03,145.94,554.16,132.94z"
          />
          <path
            d="M278.81,191.45c-13.59,0-26.25,0-39.23,0c-1.9-5.68-3.79-11.33-5.77-17.28c-12.85,0-25.79,0-39.22,0
            c-1.84,5.47-3.78,11.23-5.79,17.18c-12.86,0-25.61,0-39.05,0c14.44-39.42,28.73-78.42,43.09-117.62c14.27,0,28.48,0,43.01,0
            C250.09,112.75,264.33,151.79,278.81,191.45z M224.53,145.45c-3.4-10.41-6.68-20.47-9.96-30.53c-0.29,0.05-0.58,0.1-0.87,0.15
            c-3.27,10.06-6.53,20.11-9.87,30.39C210.87,145.45,217.45,145.45,224.53,145.45z"
          />
          <path
            d="M418.18,117.65c12.05,0,23.77,0,35.49,0.01c0.12,0,0.25,0.17,0.64,0.44c0,8.9,0,17.99,0,27.42
            c-6.16,0-12.15,0-18.15,0c-5.86,0-11.72,0-17.9,0c0,5.34,0,10.6,0,16.21c13.58,0,27.14,0,41.04,0c0,10.02,0,19.71,0,29.64
            c-26.02,0-51.96,0-78.19,0c0-39.18,0-78.3,0-117.66c26,0,51.93,0,78.19,0c0,9.59,0,19.16,0,29.22c-13.5,0-27.19,0-41.13,0
            C418.18,107.93,418.18,112.46,418.18,117.65z"
          />
        </g>
      </svg>
      {/* <LogoMateo
        fill={props.color}
        style={{ width: '100%', transition: 'all 1s ease' }}
      /> */}
    </div>
  );
});

LogoSVG.displayName = "Logo";

export default LogoSVG;
