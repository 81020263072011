import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { useTailwindBreakpoints } from "../../hooks/useTailwindBreakpoints";
import { useSize } from "../../contexts/ContainerSizeContext";
import SuggestionCard from "./SuggestionCard";
import { getQuestions } from "../../data/getQuestions";
import { colorDark, colorLight } from "../../helpers/ColorManipulation";
import { BsStars } from "react-icons/bs";
import { BiSend, BiLoader, BiReset } from "react-icons/bi";
import { getChatResponse } from "../../services/getChatResponse";
import { FaWhatsapp } from "react-icons/fa";

function AskMe() {
  const { isMobile } = useTailwindBreakpoints();
  const { size: askMeContainerSize } = useSize("ask-me-container");

  const [isOpen, setIsOpen] = useState(false);
  const [questions, setQuestions] = useState(null);
  const askMeBaseContainerRef = useRef();
  const askMeInputRef = useRef();

  //Chat States
  const [chatInput, setChatInput] = useState("");
  const [chatResponse, setChatResponse] = useState("");
  const [loading, setLoading] = useState(false);

  const [portfolioChat, setPortfolioChat] = useState(false);
  const [contactChat, setContactChat] = useState(false);

  const getPrincipalContainerClassnames = (isOpen) => {
    let classNames =
      "z-20 ease-in-out duration-200 px-6 py-2 md:py-4 shadow-md hover:shadow-lg w-full";
    if (isOpen) {
      classNames +=
        " transition-[border-radius, height]  rounded-2xl fixed top-0 shadow-xl";
    } else {
      classNames +=
        " transition-[border-radius, height] cursor-pointer  rounded-full bottom-0 hover:scale-105";
    }
    return classNames;
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  const handleChatInput = (event) => {
    setChatInput(event.target.value);
  };

  const handleSuggestionInput = async (suggestion) => {
    setChatInput(suggestion);
    handleChatSubmit();
  };

  const handleChatSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await getChatResponse(chatInput);
    setChatInput("");

    setChatResponse(response.choices[0].message.content);
    setLoading(false);
  };

  const handleContact = () => {
    window.open("https://wa.me/573249247206", "_blank");
  };

  const handleReset = () => {
    setChatResponse("");
  };

  useEffect(() => {
    if (askMeBaseContainerRef.current) {
      if (isOpen && askMeBaseContainerRef.current) {
        askMeBaseContainerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }

    if (isOpen && askMeInputRef.current) {
      askMeInputRef.current.focus();
    }

    //if isOpen is close, scroll to the top smoothly
    if (!isOpen) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [isOpen]);

  useEffect(() => {
    setQuestions(getQuestions(2));
  }, [isOpen]);

  useEffect(() => {
    if (chatResponse === "<>Portafolio<>") {
      setPortfolioChat(true);
    } else {
      setPortfolioChat(false);
    }

    if (chatResponse === "<>Enviar Mensaje<>") {
      setContactChat(true);
    } else {
      setContactChat(false);
    }
  }, [chatResponse]);

  // if (isMobile) {
  //   return createPortal(
  //     <div className="bg-slate-300 rounded-t-lg w-full p-4 fixed bottom-0">
  //       <div className="flex justify-between items-center mb-2">
  //         <button className="text-gray-500 hover:text-gray-700">
  //           <svg
  //             className="h-6 w-6"
  //             fill="none"
  //             stroke="currentColor"
  //             viewBox="0 0 24 24"
  //             xmlns="http://www.w3.org/2000/svg"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth="2"
  //               d="M6 18L18 6M6 6l12 12"
  //             />
  //           </svg>
  //         </button>
  //       </div>
  //     </div>,
  //     document.body
  //   );
  // }

  return (
    <div
      ref={askMeBaseContainerRef}
      className={`${getPrincipalContainerClassnames(isOpen)} bg-[#fed6cd]`}
      style={{
        // width: isOpen ? askMeContainerSize.width + 200 : 'fit-content',
        height: isOpen ? "fit-content" : 70,
        border: isOpen ? "2px solid #f23006" : "none",
      }}
      onClick={handleOpen}
    >
      <div
        className={`flex place-items-center justify-center w-full flex-col ${
          !isOpen && "h-full"
        }`}
      >
        <div
          className={`flex justify-between items-center w-full ${
            isOpen && "pt-4"
          }`}
        >
          <h2
            className="text-lg md:text-xl w-full text-center font-bold flex items-center"
            style={{ color: "#f23006" }}
          >
            <BsStars className="mr-2" />
            Hazme una pregunta
          </h2>
          {isOpen && (
            <button
              onClick={handleClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
        </div>

        <div
          className="w-full transition-all ease-in-out duration-300 delay-200"
          style={{ height: isOpen ? 200 : 0 }}
        >
          {isOpen && !chatResponse && (
            <div className="">
              <p className="mt-1 text-gray-600" style={{ fontSize: "0.7rem" }}>
                Las respuestas pueden no ser precisas ni representar mis
                opiniones exactas.
              </p>
              {questions.map((question, index) => {
                return (
                  <SuggestionCard
                    onClick={() => handleSuggestionInput(question.description)}
                    key={index}
                    Icon={question.icon}
                    description={question.description}
                    color={question.color}
                  />
                );
              })}
            </div>
          )}

          {chatResponse && isOpen && (
            <div className="w-full h-full overflow-auto py-2">
              <p style={{ color: colorDark("#f35644") }}>
                {portfolioChat || contactChat ? "" : chatResponse}
              </p>
              {portfolioChat && (
                <div
                  onClick={() => (window.location.href = "/#portfolio")}
                  className="flex justify-center items-center ml-2 bg-white shadow-sm px-2 py-1 rounded-md w-fit mt-2 cursor-pointer hover:scale-105 transition-all ease-in-out"
                >
                  <BsStars className="text-yellow-500" />
                  <p className="text-gray-500 ml-1 text-sm">Ver Portafolio</p>
                </div>
              )}
              {contactChat && (
                <div
                  onClick={handleContact}
                  className="flex justify-center items-center ml-2 bg-white shadow-sm px-2 py-1 rounded-md w-fit mt-2 cursor-pointer hover:scale-105 transition-all ease-in-out"
                >
                  <FaWhatsapp className="text-green-500" />
                  <p className="text-gray-500 ml-1 text-sm">
                    Envíame un mensaje
                  </p>
                </div>
              )}
              <div className="flex w-full flex-row">
                <div
                  onClick={handleReset}
                  className="flex justify-center items-center ml-2 bg-white shadow-sm px-2 py-1 rounded-md w-fit mt-2 cursor-pointer hover:scale-105 transition-all ease-in-out"
                >
                  <BiReset className="text-gray-500" />
                  <p className="text-gray-500 ml-1 text-sm">Reiniciar</p>
                </div>
                {!contactChat && (
                  <div
                    onClick={handleContact}
                    className="flex justify-center items-center ml-2 bg-white shadow-sm px-2 py-1 rounded-md w-fit mt-2 cursor-pointer hover:scale-105 transition-all ease-in-out"
                  >
                    <FaWhatsapp className="text-green-500" />
                    <p className="text-gray-500 ml-1 text-sm">Contactar</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {isOpen && (
          <div className="flex justify-between items-center w-full mt-2">
            <input
              disabled={loading}
              ref={askMeInputRef}
              value={chatInput}
              onChange={handleChatInput}
              type="text"
              placeholder="Preguntame"
              className="w-full h-12 border border-gray-300 rounded-full px-4 text-gray-700"
            />
            <button
              disabled={loading || !chatInput}
              onClick={handleChatSubmit}
              className={`${
                loading || !chatInput ? "bg-[#b07f79]" : "bg-[#f25644]"
              } text-white rounded-full p-4 ml-2`}
            >
              {loading ? <BiLoader className="animate-spin" /> : <BiSend />}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AskMe;
