import React from "react";

function Star({className, color="#fed6cd", width="100%"}) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 300 300"
      className={className}
      style={{ width: width }}
    >
      <path fill={color} d="M157.37,25.83l13.41,39.98c1.66,4.95,7.63,6.89,11.89,3.86l34.35-24.46c6.08-4.33,14.17,1.55,11.93,8.67l-12.65,40.23c-1.57,4.98,2.12,10.06,7.35,10.11l42.17.4c7.46.07,10.55,9.58,4.56,14.03l-33.88,25.11c-4.2,3.11-4.2,9.39,0,12.5l33.88,25.11c5.99,4.44,2.9,13.96-4.56,14.03l-42.17.4c-5.22.05-8.91,5.13-7.35,10.11l12.65,40.23c2.24,7.12-5.86,13-11.93,8.67l-34.35-24.46c-4.25-3.03-10.22-1.09-11.89,3.86l-13.41,39.98c-2.37,7.07-12.38,7.07-14.75,0l-13.41-39.98c-1.66-4.95-7.63-6.89-11.89-3.86l-34.35,24.46c-6.08,4.33-14.17-1.55-11.93-8.67l12.65-40.23c1.57-4.98-2.12-10.06-7.35-10.11l-42.17-.4c-7.46-.07-10.55-9.58-4.56-14.03l33.88-25.11c4.2-3.11,4.2-9.39,0-12.5l-33.88-25.11c-5.99-4.44-2.9-13.96,4.56-14.03l42.17-.4c5.22-.05,8.91-5.13,7.35-10.11l-12.65-40.23c-2.24-7.12,5.86-13,11.93-8.67l34.35,24.46c4.25,3.03,10.22,1.09,11.89-3.86l13.41-39.98c2.37-7.07,12.38-7.07,14.75,0Z"/>
    </svg>
  );
}

export default Star;
