import React, { ReactNode } from "react";
import { IconType } from "react-icons"; // Import the IconType
import { colorLight } from "../../helpers/ColorManipulation";

interface TagProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
  icon?: IconType; // Use IconType for react-icons
  color?: string;
  size?: "large" | "small";
}

function Tag({
  children,
  className,
  icon: Icon,
  color,
  size = "large",
  ...rest
}: TagProps) {
  if (size === "small") {
    return (
      <div
        {...rest}
        style={{ background: color, color: colorLight(color) }}
        className={`rounded-full py-1 px-2 m-1 shadow-sm w-fit ${className} text-sm  transform transition-transform duration-200 ease-in-out`}
      >
        <div className="flex items-center font-semibold mr-1">
          <div
            className="rounded-full flex items-center mr-1 p-1 -ml-1"
            style={{ background: colorLight(color) }}
          >
            {Icon && <Icon className="text-md" color={color} />}
          </div>
          <p className="text-nowrap font-gabarito">{children}</p>
        </div>
      </div>
    );
  }

  if (size === "large") {
    return (
      <div
        {...rest}
        style={{ background: color, color: colorLight(color) }}
        className={`rounded-full py-2 px-4 m-1 shadow-md w-fit ${className} text-lg  transform transition-transform duration-200 ease-in-out`}
      >
        <div className="flex items-center font-semibold mr-2">
          <div
            className="rounded-full flex items-center mr-2 p-2 -ml-2"
            style={{ background: colorLight(color) }}
          >
            {Icon && <Icon className="text-xl" color={color} />}
          </div>
          <p className="text-nowrap">{children}</p>
        </div>
      </div>
    );
  }
}

export default Tag;
