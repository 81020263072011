import React, { useState, useEffect } from "react";
import { BiCodeAlt } from "react-icons/bi";

function Tabs({ children, tabs, onChange, onTabClick }) {
  const [selected, setSelected] = useState(1);

  useEffect(() => {
    if (onChange) {
      onChange(selected);
    }
  }, [selected]);

  const handleClickedTab = (index) => {
    setSelected(index);
    if (onTabClick) {
      onTabClick();
    }
  };
  return (
    <div
      className="flex flex-row flex-nowrap w-fit sticky m-auto p-1 z-50 rounded-md shadow-lg bg-[#f35644] font-gabarito"
      style={{ top: `1rem` }}
    >
      {tabs.map((tab, index) => {
        return (
          <button
            key={index}
            className={`flex items-center p-2 m-1 rounded-md ${
              selected === index
                ? "bg-[#fed6cd] text-[#f35644] font-bold"
                : "bg-[#f35644] text-[#fed6cd]"
            }`}
            onClick={() => handleClickedTab(index)}
          >
            {tab.icon && selected === index && <tab.icon className="mr-1" />}
            {tab.name}
          </button>
        );
      })}
    </div>
  );
}

export default Tabs;
