export const getChatResponse = async (chatInput) => {
  try {
    const response = await fetch(
      "https://us-central1-mateoportfolio.cloudfunctions.net/askMateo",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: chatInput,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
