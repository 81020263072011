import React from "react";

const BlockContainer = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} {...props}>
      {props.children}
    </div>
  );
});

export default BlockContainer;
