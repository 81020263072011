import React from "react";
import Card from "../../../components/Card";
import FooterWaves from "../../../assets/components/FooterWaves";
import LogoSVG from "../../../components/shapes/MateoLogo";
import Tag from "../../../components/Tag";
import { SiGithub, SiGmail, SiWhatsapp } from "react-icons/si";

function ContactMe() {
  return (
    <div
      className="w-full overflow-hidden"
      style={{
        width: "calc(100% + 1rem)",
        marginBottom: "-0.5rem",
        marginLeft: "-0.5rem",
        borderBottomLeftRadius: "0.9rem",
        borderBottomRightRadius: "0.9rem",
      }}
    >
      <div
        className="p-4 relative"
        style={{
          background: "#f35644",
          borderWidth: "2px",
          borderColor: "#f35644",
          width: "calc(100% + 1rem)",
          marginLeft: "-0.5rem",
          marginBottom: "-0.5rem",
          marginTop: 100,
          borderBottomLeftRadius: "0.9rem",
          borderBottomRightRadius: "0.9rem",
        }}
      >
        <FooterWaves
          className="absolute"
          style={{ top: -100, width: "1450px", left: "-0.5rem" }}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-screen-lg m-auto pb-4">
          <div className="pl-4">
            <h3 className="text-2xl" style={{ color: "#fed6cd" }}>
              Contáctame
            </h3>
            <Tag
              className="cursor-pointer"
              color="#f35644"
              icon={SiWhatsapp}
              size="small"
              onClick={() => {
                window.open("https://wa.me/573249247206", "_blank");
              }}
            >
              WhastApp
            </Tag>
            <Tag
              className="cursor-pointer"
              color="#f35644"
              icon={SiGmail}
              size="small"
              onClick={() => {
                window.open("mailto:contacto@mateoavila.com", "_blank");
              }}
            >
              contacto@mateoavila.com
            </Tag>
            <Tag
              className="cursor-pointer"
              color="#f35644"
              icon={SiGithub}
              size="small"
              onClick={() => {
                window.open("https://github.com/Mateo172001", "_blank");
              }}
            >
              Mateo172001
            </Tag>
          </div>
          <div>
            <LogoSVG
              alignSelf="start"
              width="35%"
              maxWidth="150px"
              fill="#fed6cd"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactMe;
