import React, { createContext, useState, useContext } from "react";

const ContainerSizeContext = createContext({
  sizes: {}, // Valor inicial predeterminado
  setSize: () => {}, // Función predeterminada para evitar errores
});

export const useSize = (id) => {
  const { sizes, setSize } = useContext(ContainerSizeContext);
  return {
    size: sizes[id] ?? { width: 0, height: 0 },
    setSize: (newSize) => setSize(id, newSize),
  };
};

export const ContainerSizeProvider = ({ children }) => {
  const [sizes, setSizes] = useState({});

  const setSize = (id, newSize) => {
    setSizes((prevSizes) => ({
      ...prevSizes,
      [id]: newSize,
    }));
  };

  return (
    <ContainerSizeContext.Provider value={{ sizes, setSize }}>
      {children}
    </ContainerSizeContext.Provider>
  );
};
