import React from "react";
import BaseContainer from "../components/BaseContainer";
import GridPage from "../components/GridPage/GridPage";
import AboutMe from "./sections/AboutMe";
import TypingEffect from "../components/TypingEffect/TypingEffect";
import WhatIDo from "./sections/WhatIDo";
import Portfolio from "./sections/Portfolio/Portfolio";
import Ejemplo from "../components/GridPage/Ejemplo";
import ContactMe from "./sections/ContactMe";
function Home() {
  return (
    <div className="font-titan flex flex-col justify-center">
      <GridPage />
      <AboutMe />
      <WhatIDo />
      <Portfolio />
      <ContactMe />
    </div>
  );
}

export default Home;
