import React from "react";
import { contrastColor } from "../../helpers/ColorManipulation";

function Card(props) {
  return (
    <div
      {...props}
      className={`${props.className} rounded-2xl p-4 pt-4 pl-4 text-sm md:text-lg`}
    >
      {props.children}
    </div>
  );
}

export default Card;
