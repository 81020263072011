import React from "react";
import { colorDark, colorLight } from "../../helpers/ColorManipulation";

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  color?: string;
}

function Button({ className, children, color, ...rest }: ButtonProps) {
  return (
    <div
      className={`w-fit py-1 px-3 cursor-pointer font-bold transition-all ease-in-out duration-200 transform hover:scale-105 ${className}`}
      {...rest}
      style={{
        background: colorLight(color),
        color: color,
        borderColor: color,
        borderWidth: "2px",
        boxShadow: `-3px 3px 0px 0px ${color}`,
      }}
    >
      <p className="text-nowrap">{children}</p>
    </div>
  );
}

export default Button;
