import React from "react";
import TypingEffect from "../../../components/TypingEffect";
import { colorDark, colorLight } from "../../../helpers/ColorManipulation";
import Duck from "../../../components/shapes/Duck";
import SkillSet from "../SkillSet/SkillSet";
import Star from "../../../components/shapes/Star";

function AboutMe() {
  return (
    <div className="font-gabarito text-sm md:text-lg w-full max-w-screen-lg mx-auto overflow-hidden">
      <p
        className="text-6xl md:text-8xl text-center mt-10 font-titan"
        style={{ color: "#522ab1" }}
      >
        ¡HOLA!
      </p>
      <h1
        className="text-4xl md:text-6xl text-center mt-3 font-titan"
        style={{ color: colorDark("#f5ebe0") }}
      >
        Soy Mateo Avila
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 mt-10 mx-4 font-semibold">
        <div className="place-content-center relative">
          <Star className="absolute -z-10 -top-[100px] w-[200%] animate-spin" />
          <p style={{ color: colorDark("#f5ebe0") }}>
            Soy desarrollador web freelance con un fuerte enfoque en crear
            experiencias digitales que conectan. Más allá del diseño gráfico, me
            apasiona transformar ideas en aplicaciones web y móviles que sean
            tanto funcionales como atractivas. Mi objetivo es entender las
            necesidades de cada proyecto y convertirlas en soluciones
            tecnológicas que realmente marquen la diferencia.
          </p>
        </div>
        <div className="mt-6 md:mt-0">
          <Duck />
        </div>
      </div>
      <h2
        className="text-3xl text-center mt-10 font-titan mb-4"
        style={{ color: "#f35644" }}
      >
        Mis habilidades
      </h2>
      <SkillSet />
    </div>
  );
}

export default AboutMe;
